import type { DataTableHeader } from "sweepatic-shared/typings/datatable";

import { router } from "@/plugins/router";
import {
  getScopes,
  updateScope,
  getScopeDetails,
  getBands,
  getScopeRoles,
  getScopeSubscopes,
} from "@/services/scopes.service";
import type { Metadata, Role, ScopesRequestPayload } from "@/typings";
import type { Getters, Mutations, Actions } from "@/typings/store";
import type { Scopes, ScopeState, Subscope } from "@/typings/store/scopes";

const DEFAULT_COLUMN_HEADERS: DataTableHeader[] = [
  { title: "", value: "actions", sortable: false, minWidth: "20px" },
  { title: "Score", value: "score", minWidth: "95px", width: "95px" },
  { title: "Scope", value: "scope", minWidth: "84px" },
  { title: "Display Name", value: "display_name", minWidth: "140px" },
  {
    title: "Account Type",
    value: "account_type",
    minWidth: "141px",
  },
  {
    title: "Modules",
    value: "modules",
    minWidth: "100px",
  },
  {
    title: "Account Lead",
    value: "account_lead",
    minWidth: "124px",
  },
  {
    title: "Created at",
    value: "created",
    width: "105px",
  },
  {
    title: "Start Date",
    value: "license.start_date",
    minWidth: "105px",
  },
  {
    title: "End Date",
    value: "license.end_date",
    minWidth: "100px",
  },
  { title: "Status", value: "disabled", minWidth: "94px" },
  { title: "Head Count", value: "license.head_count", minWidth: "120px" },
  { title: "Current Assets", value: "total_domains", minWidth: "130px" },
];

const state: ScopeState = {
  columnHeaders: [],
  currentScope: undefined,
  scopeRoles: {},
  filter: "",
  filterError: "",
  scopes: [],
  subscopes: {},
  accountTypes: ["enterprise", "premium", "trial", "prospect", "demo", "inactive", "benchmark"],
  workflowStages: [
    "new",
    "onboarding",
    "verified",
    "meeting",
    "send trial",
    "send offer",
    "payment received",
    "customer",
    "renew",
  ],
  showDisabled: false,
  total: 0,
  dynamicQuickfilters: {
    score: [
      { value: "A", default: false },
      { value: "B", default: false },
      { value: "C", default: false },
      { value: "D", default: false },
      { value: "E", default: false },
      { value: "F", default: false },
    ],
    account_type: [{ value: "Demo", default: false }],
  },
  isQuickFiltersfetched: false,
  bands: {},
};

const getters: Getters<ScopeState> = {
  scopes: (state) => state.scopes,
  total: (state) => state.total,
  columnHeaders: (state) => state.columnHeaders,
  filter: (state) => state.filter,
  filterError: (state) => state.filterError,
  accountTypes: (state) => state.accountTypes,
  workflowStages: (state) => state.workflowStages,
  currentScope: (state) => state.currentScope,
  showDisabled: (state) => state.showDisabled,
  dynamicQuickfilters: (state) => state.dynamicQuickfilters,
  bands: (state) => state.bands,
  scopeRoles: (state) => state.scopeRoles,
  subscopes: (state) => state.subscopes,
  scopeList: (state) => state.scopes.map((scope) => scope.scope),
};

const mutations: Mutations<ScopeState> = {
  setScopes(state, { scopes, total }: { scopes: Scopes; total: number }) {
    state.scopes = scopes;
    state.total = total;
  },

  setColumnHeaders(state, columnHeaders) {
    state.columnHeaders = columnHeaders;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  toggleScope(state, scopeName) {
    state.scopes = state.scopes.map((scope) => {
      if (scope.scope === scopeName) {
        scope.disabled = !scope.disabled;
      }

      return scope;
    });
  },
  setFilterError(state, payload) {
    state.filterError = payload;
  },

  setCurrentScope(state, scope) {
    state.currentScope = scope;
  },

  setShowDisabled(state, showDisabled) {
    state.showDisabled = showDisabled;
  },

  setDynamicQuickFilters(state, dynamicQuickfilters) {
    state.dynamicQuickfilters = dynamicQuickfilters;
    state.isQuickFiltersfetched = true;
  },

  setIsQuickFiltersFetched(state, payload) {
    state.isQuickFiltersfetched = payload;
  },

  setBands(state, bands) {
    state.bands = bands;
  },

  setScopeRoles(state, payload: { scope: string; roles: Role[] }) {
    state.scopeRoles[payload.scope] = payload.roles;
  },

  setSubscopes(state, payload: { scope: string; subscopes: Subscope[] }) {
    state.subscopes[payload.scope] = payload.subscopes;
  },
};

const actions: Actions<ScopeState> = {
  getColumnHeaders({ commit, rootGetters }) {
    const scopeMetadata = rootGetters["session/scopeMetadata"] || [];
    // TODO: type data / scopeMetadata
    const additionalHeaders: DataTableHeader[] = scopeMetadata.map((data: Metadata) => {
      return { title: data.display_name, value: data.value };
    });

    commit("setColumnHeaders", [...DEFAULT_COLUMN_HEADERS, ...additionalHeaders]);
  },

  async getScopes({ commit, state, rootGetters }, payload: ScopesRequestPayload) {
    const partnerName = rootGetters["session/partner"];
    payload.disabled = state.showDisabled;

    const [scopes, total] = await Promise.all([
      getScopes(partnerName, { ...payload, command: "get" }),
      getScopes(partnerName, { ...payload, command: "total" }),
    ]);
    commit("setScopes", { scopes, total });
  },

  async getSubscopes({ commit, state, rootGetters }, scope) {
    if (state.subscopes[scope]) {
      return;
    }

    commit("setSubscopes", {
      scope,
      subscopes: [
        { display_name: "Global Scope", _id: null },
        ...((await getScopeSubscopes(scope, rootGetters["session/partner"])) || []),
      ],
    });
  },

  async getDynamicQuickFilters({ commit, state, rootGetters }) {
    if (state.isQuickFiltersfetched) {
      return;
    }

    const partnerName = rootGetters["session/partner"];
    const payload = { disabled: state.showDisabled };

    const _dynamicQuickfilters = await getScopes(partnerName, {
      ...payload,
      command: "get_dynamic_quick_filters",
    });

    if (_dynamicQuickfilters) {
      commit("setDynamicQuickFilters", _dynamicQuickfilters);
    } else return;
  },

  setFilter({ commit }, filter: string) {
    commit("setFilter", filter);
  },

  async toggleScopeStatus({ commit }, payload) {
    await updateScope(payload.scope, payload.payload);
    commit("toggleScope", payload.scope);
  },

  setFilterError({ commit }, payload) {
    commit("setFilterError", payload);
  },

  setCurrentScope({ commit }, scope) {
    commit("setCurrentScope", scope);
  },

  async getCurrentScope(
    { commit },
    { scopeName, partnerName }: { scopeName: string; partnerName: string },
  ) {
    const currentScope = await getScopeDetails(partnerName, scopeName);

    if (!currentScope) {
      throw new Error("Scope does not exists");
    }
    commit("setCurrentScope", currentScope);
  },

  setShowDisabled({ commit, dispatch }, showDisabled: boolean) {
    commit("setShowDisabled", showDisabled);

    const { sortBy, sortOrder, filter, pageSize } = router.currentRoute.value.query;

    dispatch("getScopes", { page: 0, sortBy, sortOrder, filter, pageSize });
    commit("setIsQuickFiltersFetched", false);
    dispatch("getDynamicQuickFilters");
  },

  async getBandLimits({ commit, rootGetters }) {
    const bands = await getBands(rootGetters["session/partner"]);
    commit("setBands", bands);
  },

  async getScopeRoles({ commit, state, rootGetters }, scope) {
    if (state.scopeRoles[scope]) {
      return;
    }

    commit("setScopeRoles", {
      scope: scope,
      roles: await getScopeRoles(scope, rootGetters["session/partner"]),
    });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
