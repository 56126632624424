<template>
  <sw-paginated-datatable
    allow-refresh
    default-sort-key="scope"
    default-sort-order="desc"
    storage-key="SCOPES_SORT"
    :data="scopes"
    :total-items="total"
    :headers="columnHeaders"
    :loading="isLoading"
    @click:row="openScopeEditorPage($event)"
    @update:data="onDataUpdate"
  >
    <template v-slot:header.score="{ column }">
      {{ column.title }}

      <sw-quick-filter-menu :field="column.title" :items="dynamicQuickfilters.score" />
    </template>

    <template v-slot:header.account_type="{ column }">
      {{ column.title }}

      <sw-quick-filter-menu :field="column.title" :items="dynamicQuickfilters.account_type" />
    </template>

    <!-- clear quick filter -->
    <template v-slot:top>
      <div class="d-flex justify-end">
        <v-btn
          v-if="!defaultQuickFilters"
          size="x-small"
          variant="outlined"
          class="mt-2 mr-2"
          @click="resetQuickFilters"
        >
          Reset quick filters
        </v-btn>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-list density="compact">
        <v-list-item
          density="compact"
          prepend-icon="fa regular fa-pen"
          title="Edit scope"
          @click="openScopeEditorPage(item as Scope)"
        />

        <v-list-item
          density="compact"
          prepend-icon="fa-regular fa-power-off"
          :title="`${item.disabled ? 'Enable' : 'Disable'} Scope`"
          @click="openDialog(item as Scope)"
        />

        <v-list-item
          density="compact"
          prepend-icon="fa-regular fa-eye"
          title="See on Platform"
          :disabled="item.disabled"
          @click="goToPlatform(item.scope)"
        />
      </v-list>
    </template>

    <template v-slot:item.score="{ item, value }">
      <sw-scope-score :total="value" :scope-history="item.scope_history" />
    </template>

    <template v-slot:item.scope="{ value }">
      <span class="text-uppercase">{{ value }}</span>
    </template>

    <template v-slot:item.disabled="{ item }">
      {{ item.disabled ? "Disabled" : "Enabled" }}
    </template>

    <template v-slot:item.modules="{ value }">
      <div class="py-1">
        <template v-for="(module, moduleType) in value" :key="moduleType">
          <v-chip
            v-if="module.enabled"
            label
            :color="
              module.licensed || (moduleType as any as ModuleType) === 'outscan'
                ? 'green'
                : 'default'
            "
            class="mr-1"
            size="x-small"
            rounded="64"
          >
            <img
              alt=""
              class="mr-1"
              height="15"
              :src="MODULE_LOGO[moduleType as any as ModuleType]"
            />
            {{ moduleType.toString().replaceAll("_", " ") }}
          </v-chip>
        </template>
      </div>
    </template>

    <template v-slot:item.license.start_date="{ value }">
      <sw-date-chip :date="value" />
    </template>

    <template v-slot:item.license.end_date="{ value }">
      <sw-date-chip :date="value" />
    </template>

    <template v-slot:item.license.renew_date="{ value }">
      <sw-date-chip :date="value" />
    </template>

    <template v-slot:item.created="{ value }">
      <sw-date-chip :date="value" />
    </template>

    <template v-slot:item.seeds="{ value }">
      {{ value.length }}
    </template>
  </sw-paginated-datatable>
</template>

<script setup lang="ts">
  import { SwDateChip, SwPaginatedDatatable } from "sweepatic-shared/components";
  import type { DataUpdatePayload, DataTableHeader } from "sweepatic-shared/typings/datatable";
  import { computed, onBeforeMount, ref, watch } from "vue";
  import { useRouter, useRoute } from "vue-router";

  import SwScopeScore from "@/components/scopes/SwScopeScore.vue";
  import SwQuickFilterMenu from "@/components/filter/SwQuickFilterMenu.vue";
  import { useStore } from "@/composables/store";
  import { useQuickFilters } from "@/composables/quickFilters";
  import { EventBus, EVENTS } from "@/EventBus";
  import type { Scope, Scopes, ModuleType } from "@/typings";
  import type { QuickFilterConfigObj } from "@/typings/store/filter";
  import { MODULE_LOGO } from "./constants";

  const $emit = defineEmits<{
    (e: "click:open-dialog", payload: { scope: string; disabled: boolean }): void;
  }>();

  const $store = useStore();
  const $router = useRouter();
  const $route = useRoute();

  const isLoading = ref<boolean>(false);
  const defaultQuickFilters = ref<boolean>(true);
  const dynamicQuickfilters = computed<Record<"score" | "account_type", QuickFilterConfigObj[]>>(
    () => $store.getters["scopes/dynamicQuickfilters"],
  );

  const columnHeaders = computed<Array<DataTableHeader>>(
    () => $store.getters["scopes/columnHeaders"],
  );
  const scopes = computed<Scopes>(() => $store.getters["scopes/scopes"]);
  const total = computed<number>(() => $store.getters["scopes/total"]);

  onBeforeMount(() => $store.dispatch("scopes/getColumnHeaders"));

  const { mapQuickFilterOptionToFilter, hasDefaultQuickFilters, getDefaultQuickFilterConfigs } =
    useQuickFilters();

  const openDialog = ({ scope, disabled }: Scope) => {
    $emit("click:open-dialog", { scope, disabled });
  };

  const openScopeEditorPage = (scope: Scope) => {
    $store.dispatch("scopes/setCurrentScope", scope);

    $router.push({ name: "scope-editor", params: { scope: scope.scope } });
  };

  const goToPlatform = (scope: string) => {
    window.open(`${import.meta.env.VITE_PLATFORM_BASE_URL}/?scope=${scope}`, "_blank");
  };

  const onDataUpdate = async (payload: DataUpdatePayload) => {
    isLoading.value = true;

    await $store.dispatch("scopes/getScopes", payload);
    isLoading.value = false;
  };

  const resetQuickFilters = async () => {
    await $store.dispatch("filter/setQuickFilters", {
      Score: mapQuickFilterOptionToFilter(dynamicQuickfilters.value.score),
      "Account Type": mapQuickFilterOptionToFilter(dynamicQuickfilters.value.account_type),
    });

    EventBus.emit(EVENTS.FILTER.QUICK_FILTER_RESET);
  };

  watch(
    () => $route.query,
    (query) => {
      if (!query.quickFilters) {
        return (defaultQuickFilters.value = true);
      }

      defaultQuickFilters.value = hasDefaultQuickFilters(
        {
          Score: getDefaultQuickFilterConfigs(dynamicQuickfilters.value.score),
          "Account Type": getDefaultQuickFilterConfigs(dynamicQuickfilters.value.account_type),
        },
        query.quickFilters as string,
      );
    },
    { immediate: true },
  );
</script>
